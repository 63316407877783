import { createContext, useContext, useEffect, useState } from "react";
import { AuthState, ModiUser } from "./ModiUser";

export { AuthState } from './ModiUser';

export const MODI_USER = createContext(new ModiUser());

export function useModiUser()
{
    // eslint-disable-next-line prefer-const
    let modiUser = useContext(MODI_USER);
    const [authState, setAuthState] = useState(modiUser.authState);

    useEffect(() => {        
        modiUser.authStateListeners.push(setAuthState);
        return () => {
            modiUser.authStateListeners = modiUser.authStateListeners.filter((l) => l !== setAuthState);
        };

    }, []);

    
    return { 
        modiUser : modiUser, 
        authState : authState,
        signIn : modiUser.signIn.bind(modiUser),
        confirmSignIn : modiUser.confirmSignIn.bind(modiUser),
        confirmSignUp : modiUser.confirmSignUp.bind(modiUser),
        modiUserSignUp : modiUser.modiUserSignUp.bind(modiUser),
        phoneNumber : modiUser.phoneNumber,
    };
}