export default function TextInput(
    props: {
        label?: string,
        name?: string,
        placeholder?: string,
        className?: string,
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void 
    }) {
    return (
        <div className="rounded-md bg-white px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-lime-600">
            <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                {props.label}
            </label>
            <input
                type="text"
                name={props.name}
                className={`block w-full border-0 outline-none p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${props.className}`}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
        </div>
    );
}