import React, { ChangeEvent } from 'react';
import modiLogo from '../assets/logo-black.png';

import { useState } from 'react';

export default function ConfirmSignIn(props : { authReturn : (code : string) => void, phoneNumber : string|undefined })
{
    
    const [code, setCode] = useState("");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value)
    }

    return (
        <div className="h-screen">
            <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8 sm:py-8 sm:bg-gray-50 sm:p-4 rounded-lg">
                <div>
                    <h2 className="text-left pb-2 text-3xl font-bold tracking-tight text-gray-800">
                        Just making sure it's you 👀
                    </h2>
                    <p>We sent a One Time Passcode to </p>
                    <p className="font-bold">{props.phoneNumber}</p>
                    <div className="pb-2 pt-4">
                        <label htmlFor="verification-code" className="block text-sm font-medium leading-6 text-gray-900">
                            Code
                        </label>
                        <input
                                type="text"
                                name="verification-code"
                                id="verification-code"
                                autoComplete="tel"
                                value={code}
                                onChange={handleChange}
                                className={"block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"}
                            />
                    </div>
                    <button type="submit"
                        className="group relative flex w-full justify-center rounded-md bg-lime-600 px-3 py-2 text-sm font-semibold text-white hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                        onClick={() => props.authReturn(code)}
                    >
                        Submit
                    </button>
                </div>
            </div>
            </div>
        </div>
    );
}