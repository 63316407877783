import {useState } from "react";
import TextInput from "../input/TextInput";
import Button from "../input/Button";

export default function SignUp(props : { modiUserSignUp : (firstName : string, lastName : string, confirmationCode: string | undefined) => Promise<void>, userIsSignedIn : boolean   } ) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [confirmationCode, setConfirmationCode] = useState<string | undefined>(undefined);

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        props.modiUserSignUp(firstName, lastName, confirmationCode);
    }

    //TODO: handle the case where they leave and come back and have to sign in again
    //if they already signed in we don't need a code
    return (<main className="h-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center px-4 py-4 sm:px-6 lg:px-8 backdrop-blur-sm">
                <div className="w-full max-w-md space-y-8 sm:py-8 sm:bg-gray-50 sm:p-4 rounded-lg">
                    <div>
                        <h2 className="text-left pb-2 text-3xl font-bold tracking-tight text-gray-800">Sign Up</h2>
                        <p className="text-left text-gray-500"> We noticed it&apos;s your first time using modi 🥳. </p>
                        {!props.userIsSignedIn &&  <p className="text-left text-gray-500">We need one more code from you 🙏. Sent it just now.</p> }
                    </div>
                    <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                        { !props.userIsSignedIn && <div className="pb-2 pt-4">
                            <label htmlFor="verification-code" className="block text-sm font-medium leading-6 text-gray-900">
                                Yet Another Code (sent to your phone)
                            </label>
                            <input
                                type="text"
                                name="verification-code"
                                id="verification-code"
                                autoComplete="tel"
                                value={confirmationCode}
                                onChange={(event) => setConfirmationCode(event.target.value)}
                                className={"block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"}
                            />
                        </div> }
                        <TextInput
                            label="First Name"
                            name="firstName"
                            placeholder="Jane"
                            onChange={(event) => setFirstName(event.target.value)}
                        />
                        <TextInput
                            label="Last Name"
                            name="lastName"
                            placeholder="Smith"
                            onChange={(event) => setLastName(event.target.value)}
                        />
                        <Button className="my-4" filled >
                            Sign Up
                        </Button>
                    </form>
                </div>
            </div>
        </main>
    );
}