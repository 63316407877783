import React, { ChangeEvent } from "react";
import { CountryCode, ParseError, parsePhoneNumber } from "libphonenumber-js";
import { useState } from "react";

const countryCodes: Record<string, string> = {
  US: "+1",
  AR: "+54",
  // Add more country codes here
};

const errorMessages: Record<string, string> = {
  TOO_SHORT: "The phone number is too short.",
  TOO_LONG: "The phone number is too long.",
  NOT_A_NUMBER: "Not a valid phone number.",
};

interface PhoneNumberInputProps {
  onChange: (phoneNumber: string) => void;
  selectedCountry: CountryCode;
  onChangeCountry: (event: ChangeEvent<HTMLSelectElement>) => void;
  errorMessage: string | undefined
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  onChange,
  selectedCountry,
  onChangeCountry,
  errorMessage,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div>
      <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-600">
        Mobile Number
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor="country" className="sr-only">
            Country
          </label>
          <select
            id="country"
            name="country"
            autoComplete="country"
            value={selectedCountry}
            onChange={onChangeCountry}
            className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm"
          >
            {Object.entries(countryCodes).map(([countryCode, phoneCode]) => (
              <option key={countryCode} value={countryCode}>
                {countryCode}
              </option>
            ))}
          </select>
        </div>
        <input
          type="text"
          name="phone-number"
          id="phone-number"
          autoComplete="tel"
          value={phoneNumber}
          onChange={handleChange}
          className={`${errorMessage && "ring-red-500 ring-2"} block w-full rounded-md border-0 py-1.5 pl-24 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6`}
          placeholder="(555) 987-6543"
        />
        <span className="text-sm absolute inset-y-0 left-14 flex items-center pl-2.5">
          <span className="before:text-gray-500 before:content" data-before-content={countryCodes[selectedCountry]}>
            {countryCodes[selectedCountry]}
          </span>
        </span>
      </div>
      { errorMessage && <div className="mt-2 text-red-500 p-2">{errorMessages[errorMessage]}</div>}
    </div>
  );
};

export default PhoneNumberInput;
