import React, { ChangeEvent, FormEvent } from "react";
import { CountryCode, isValidPhoneNumber, ParseError, parsePhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { LockClosedIcon } from '@heroicons/react/20/solid'
import modiLogo from "../assets/logo-black.png"
import PhoneNumberInput from "../input/PhoneInput";


const countryCodes: Record<string, string> = {
    US: "+1",
    AR: "+54",
    // Add more country codes here
};

const errorMessages: Record<string, string> = {
    TOO_SHORT: "The phone number is too short.",
    TOO_LONG: "The phone number is too long.",
    NOT_A_NUMBER: "Not a valid phone number.",
}

export default function SignIn( props : { signIn : (phoneNumber : string) => void})
{

    const [phone_number, setPhoneNumber] = useState("");
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>("US")
    const [errorMessage, setErrorMessage] = useState("")

    const onSignIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try{
            const parsed_number = parsePhoneNumber(`${countryCodes[selectedCountry]}${phone_number}`, selectedCountry);
            if (parsed_number && parsed_number.isValid()) props.signIn(parsed_number.number);
        }
        catch(e)
        {
            console.log(e)
            if (e instanceof ParseError) setErrorMessage((e as ParseError).message);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        switch (event.target.name) {
            case "country":
                setSelectedCountry(event.target.value as CountryCode)
                break;
            case "phone-number":
                setPhoneNumber(event.target.value)
                break;
            default:
                break;
        }
    }
    
    return(
        <div className="h-screen">
            <div className="flex min-h-full items-center justify-center py-12 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8 sm:py-8 sm:bg-gray-50 h-full p-4 sm:rounded-lg ">
                    <div>
                        <h2 className="text-left pb-2 text-3xl font-bold tracking-tight text-gray-800">
                            Log in using your phone
                        </h2>
                        <p className="text-gray-500">To keep your account secure, we’ll send you a code to log in</p>
                    </div>
                    <form
                        onSubmit={onSignIn}
                        className="mt-8 space-y-2" action="#" method="POST">
                        <input type="hidden" name="remember" defaultValue="true" />
                        <PhoneNumberInput
                            onChange={setPhoneNumber}
                            selectedCountry={selectedCountry}
                            onChangeCountry={handleChange}
                            errorMessage={errorMessage}
                        />
                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md bg-lime-600 px-3 py-2 text-sm font-semibold text-white hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-lime-500 group-hover:text-lime-400" aria-hidden="true" />
                                </span>
                                Continue
                            </button>
                        </div>
                        <p className="text-xs pt-2 px-1 text-gray-500">
                            by entering and pressing Continue you consent to recieving SMS messages from Modi and agree to our <a className="text-indigo-600 underline" href="https://tenant.modi.green/TenantCustomerAgreement.pdf">Tenant Agreement </a> and <a className="text-indigo-600 underline" href="/PrivacyPolicy.pdf">Privacy Policy.</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}