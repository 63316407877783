export class ModiAuthError implements Error
{
    name: string;
    message: string;
    stack?: string | undefined;
    cause?: unknown;
    value: ModiAuthErrorValue | undefined;

    constructor(error : unknown)
    {
        this.value = getModiAuthError(error);
        this.name = 'ModiAuthError';
        this.message = this.value ?? 'Unknown ModiAuthError';
        this.stack = (error as Error).stack;
        this.cause = error;
    }
}

export enum ModiAuthErrorValue {
    UserNotSignedIn = 'UserNotSignedIn',
    UserNotFoundException = 'UserNotFoundException',
    UserNotConfirmedException = 'UserNotConfirmedException',
    ModiUserNotFoundException = 'ModiUserNotFoundException',
}

function getModiAuthError(error : unknown) : ModiAuthErrorValue | undefined
{
    switch((error as Error).name) {
        case 'UserNotSignedInException':
            return ModiAuthErrorValue.UserNotSignedIn;
        case "UserNotFoundException":
            return ModiAuthErrorValue.UserNotFoundException;
        case "UserNotConfirmedException":
            return ModiAuthErrorValue.UserNotConfirmedException;
        case "ApolloError":
            return getModiAuthErrorFromApolloError(error);
        default:
            return getModiAuthErrorFromMessage(error);
    }
}

function getModiAuthErrorFromApolloError(error: unknown) : ModiAuthErrorValue | undefined
{
    switch((error as Error).message) {
        case "User not logged in":
            return ModiAuthErrorValue.ModiUserNotFoundException;
        default:
            console.log('something has gone horribly wrong');
            console.log('error:' + error as string);
            console.error(error);
            return undefined;
    }
}

function getModiAuthErrorFromMessage(error: unknown) : ModiAuthErrorValue | undefined
{
    switch(error as string) {
        case "The user is not authenticated":
            return ModiAuthErrorValue.UserNotSignedIn;
        default:
            console.log('something has gone horribly wrong');
            console.log('error:' + error as string);
            console.error(error);
            return undefined;
    }

}