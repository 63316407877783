import React, { useEffect } from "react"
import modiLogo from '../assets/logo-black.png';

export default function SplashScreen() {
    return (
        <div className="h-screen w-screen relative min-h-[70px]">
            <div className="p-4  w-full h-full flex flex-col gap-2 text-center rounded-t-xl animate-jump-in justify-center">
                <img
                    className="mx-auto w-auto h-10"
                    src={modiLogo}
                    alt="Modi"
                />
                <p className="text-sm text-slate-400">v1.0.0</p>
            </div>
        </div>
    )
}