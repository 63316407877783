
//createTech(firstName: String!, lastName: String!, phone: String!): Tech
export const CREATE_TECH = `mutation CreateTech($firstName: String!, $lastName: String!, $phone: String!) {
    createTech(firstName: $firstName, lastName: $lastName, phone: $phone) {
        id
        firstName
        lastName
        phone
    }
}
`;

//addUserToTechList: User
export const ADD_USER_TO_TECH_LIST = `mutation AddUserToTechList {
    addUserToTechList {
        id
        firstName
        lastName
    }
}
`;

//addTechToProperty(propertyId: ID!, techId: ID!): Tech
export const ADD_TECH_TO_REGION = `mutation AddTechToRegion($regionId: ID!, $techId: ID!) {
    addTechToRegion(regionId: $regionId, techId: $techId) {
        id
        firstName
        lastName
        phone
    }
}
`;

export const ADD_TIME_BLOCK_TO_SCHEDULE = `mutation AddTimeBlockToSchedule($timeBlock: TimeBlockInput!, $regionTechId: ID!) {
    addTimeBlockToSchedule(timeBlock: $timeBlock, regionTechId: $regionTechId) {
        id
    }
}`;

//techs: [Tech],
export const TECHS = `query GetTechList { 
    techs {
        id
        firstName
        lastName
        phone
    }
}
`;

export const GET_TECH = `query GetTech($id: ID!) {
    tech(id: $id) {
        id
        firstName
        lastName
        phone
        allTechUnitServices {
            id
            unit {
                unitNumber
            }
            timeBlock {
                startTime
                endTime
            }
            status
            date
            price
            tenant {
                firstName
                lastName
                phone
            }
        }


    }
}
`;