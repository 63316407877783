import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ModiAuthenticator from 'modi_components/src/stories/auth/ModiAuthenticator';
import { useModiUser } from 'modi_backend_gql/src/client/react';

Amplify.configure(awsExports);

// @ts-ignore
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  }
]);

function TechAppRoot()
{
  const {
    modiUser, 
    authState,
    signIn,
    confirmSignIn,
    confirmSignUp,
    modiUserSignUp
  } = useModiUser();

  return (
    <ModiAuthenticator 
      authState={authState}
      signIn={signIn}
      confirmSignIn={confirmSignIn}
      confirmSignUp={confirmSignUp}
      modiUserSignUp={modiUserSignUp}
      phoneNumber={modiUser.phoneNumber}
      apolloClient={modiUser.getApolloClient()}
    >
      <RouterProvider router={router}/>
    </ModiAuthenticator>
  );
}

root.render(<TechAppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
