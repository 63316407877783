
//createUnitService(techId: ID!, unitId: ID!, date: String!, timeBlockId: ID!): UnitService
export const BOOK_UNIT_SERVICE = `mutation BookUnitService($techId: ID!, $date: String!, $timeBlockId: ID!) {
    bookUnitService(techId: $techId, date: $date, timeBlockId: $timeBlockId) {
      id
      date
      timeBlockId
      isSubscription
      paymentIntentClientSecret
      timeBlock {
        id
        startTime
        endTime
      }
      techId
      tech {
        id
        firstName
        lastName
      }
      propertyId
      price
      unit {
        id
        unitNumber
        unitType {
          sqft
          name
        }
        tenant {
          id
        }
      }
    }
}
`;

export const UPDATE_UNIT_SERVICE_STATUS = `mutation UpdateUnitServiceStatus($unitServiceId: ID!, $status: UnitServiceStatus!) {
    updateUnitServiceStatus(unitServiceId: $unitServiceId, status: $status) {
      id
      status
    }
}
`;

//bookUnitServiceSubscription: async (_, { techId, date, timeBlockId, subscriptionAmount }, contextValue : ContextValue)
export const BOOK_UNIT_SERVICE_SUBSCRIPTION = `mutation BookUnitServiceSubscription($techId: ID!, $date: String!, $timeBlockId: ID!, $subscriptionAmount: SubscriptionAmount!) {
    bookUnitServiceSubscription(techId: $techId, date: $date, timeBlockId: $timeBlockId, subscriptionAmount: $subscriptionAmount) {
      id
      rRule
      unitTenantId
      timeBlockId
      timeBlock {
        id
        startTime
        endTime
      }
      date
      techId
      tech {
        id
        firstName
        lastName
      }
      propertyId
      stripeSubscriptionId
      unit {
        id
        unitNumber
        unitType {
          sqft
          name
        }
        tenant {
          id
        }
      }
    }
}
`;

//getAvailibleUnitServices(propertyId: ID!, startDate: String!, endDate: String!): [TimeBlock]
export const GET_AVAILIBLE_UNIT_SERVICES = `query GetAvailibleUnitServices {
  user {
    residence{
      id
      availibleUnitServices {
        date
        price
        unitServices {
          id
          date
          price
          timeBlock {
            id
            name
            startTime
            endTime
          }
          tech {
            firstName
            lastName
            id
          }
        }
      }
    }
  }
}
`;