import { useModiUser } from 'modi_backend_gql/src/client/react';
import { ADD_USER_TO_TECH_LIST } from 'modi_backend_gql/src/client/tech';
import './App.css';
import { gql, useMutation } from '@apollo/client';
import ButtonModiTheme from 'modi_components/src/buttons/ButtonModiTheme';
import { CheckCircleIcon, LockClosedIcon } from '@heroicons/react/20/solid';


function App() {

  const { modiUser } = useModiUser();

  const isTech = modiUser.isTech();

  const [markUserAsTech, { data, loading, error }] = useMutation(gql(ADD_USER_TO_TECH_LIST), {
    onCompleted: () => {
      modiUser.checkModiUser();
      console.log('User added to tech list');
      console.log(modiUser);
      //refresh the page
      window.location.reload();
    },
    onError: (error) => {
      console.log(error);
    }
  
  });


  return (
    <div className="App">
      <main className='mx-auto max-w-md w-screen h-screen flex items-center justify-center p-4'>
        {isTech && <div>Thanks for signing up to be a cleaner with modi! More app features coming soon.</div>}
        {
          !isTech && 
          <button
              onClick={() => markUserAsTech()}
              className="group relative flex w-full justify-center rounded-md bg-lime-600 px-3 py-2 text-sm font-semibold text-white hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <CheckCircleIcon className="h-5 w-5 text-lime-500 group-hover:text-lime-400" aria-hidden="true" />
              </span>
              Sign up to be a cleaner
          </button>
        
        }
      </main>
    </div>
  ); 
}

export default App;
